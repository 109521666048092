import React from 'react'

// components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import AktuelleProjekteList from '../../../components/ImmobilienListProjektAktuell'


const CurrentProjectsPage = () => {

    return (
        <Layout logoImmobilienSelected={true}>
            
            <Seo
                title="Gips. Stuck. Trockenbau. Unsere Leistungen."
            />

            <section className="container px-4 pt-40 pb-12 mx-auto lg:px-24 xl:px-32">
                <div>
                    <h2 className="pb-2 text-secondary">Immer mit Stil, immer individuell</h2>
                    <h1 className="pb-12 h1"><span className="text-gray-800">Entwicklung hochwertiger Immobilien</span>.</h1>
                </div>
                <div className="max-w-md ml-auto mr-0 article">
                    <p>Die Hossmann Immobilien AG plant und entwickelt Immobilien sowohl für das eigene Portfolio, als auch Stockwerkeigentumobjekte. Auf Qualität und grosszügige Grundrisse wird dabei stets grossen Wert gelegt.</p>
                    <p>
                    Haben Sie Fragen zu den Objekten?<br/>
                    Rufen Sie einfach an unter <a href="tel:+41816500030">+41 (0)81 650 00 30</a>.
                    </p>
                </div>
            </section>

            <section className="container px-4 py-4 mx-auto md:py-12 lg:py-24 lg:px-24 xl:px-32">
                {/* <AktuelleProjekteList/> */}
            </section>

        </Layout>
    );
}

export default CurrentProjectsPage;