import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function AktuelleProjekteList() {
    const data = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {projektart: {eq: "aktuelles Projekt"}}, fileAbsolutePath: {regex: "/projekte(?!\/sample)/"}}) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            date
            title
            kurzbeschreibung
            m
            ort
            plz
            zimmer
            bezug
            adresse
            link
            wohnungen
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 608
                  height: 342
                  transformOptions: {cropFocus: CENTER}
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
  `)
    const posts = data.allMdx.edges
    return (
        <div>
            <div className="grid grid-cols-1 gap-4 row-gap-16 md:grid-cols-3 lg:gap-6 xl:gap-8">
                {!!posts.length &&
                    posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.slug
                        const excerpt = node.frontmatter.kurzbeschreibung || node.excerpt
                        const image = getImage(
                            node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                        )
                        return (
                            <div key={node.slug}>
                                    <a href={node.frontmatter.link} className="group font-semibold" target="_blank">
                                        <div className="w-full transition-all duration-300 transform group-hover:shadow-2xl group-hover:-translate-y-2">
                                    {node.frontmatter.bezug && (
                                        <p className="absolute top-0 left-0 z-10 px-2 py-1 text-xs text-white transition-all duration-300 transform bg-gray-800 group-hover:shadow-lg group-hover:-translate-y-2 group-hover:-translate-x-2 delay-50">
                                            Bezug für {node.frontmatter.bezug} geplant
                                        </p>
                                    )}

                                        {!!node.frontmatter.featuredImage && (
                                            <GatsbyImage image={image} />
                                        )}
                                    </div>
                                    </a>
                                   <div className="mt-3">
                                    <div className="py-2">
                                            <h3 className="font-serif font-bold text-gray-900">
                                                {title}
                                            </h3>
                                        <h4 className="mb-2 text-sm">{excerpt}</h4>
{/*                                         <p className="text-sm text-gray-600">
                                            {node.frontmatter.adresse}, {node.frontmatter.plz}&nbsp;
                                            {node.frontmatter.ort}
                                        </p> */}
                                    </div>
                                    <div className="grid grid-cols-4 py-2 mb-2 text-center md:py-3">
                                    {node.frontmatter.zimmer && (
                                        <div className="overflow-hidden leading-4">
                                            <span className="text-sm font-semibold">
                                                {node.frontmatter.zimmer}
                                            </span>
                                            <span className="block text-xs text-gray-600">
                                                Zimmer
                                            </span>
                                        </div>
                                         )}
                                        {node.frontmatter.m && (
                                        <div className="overflow-hidden leading-4">
                                            <span className="text-sm font-semibold">
                                                {node.frontmatter.m}
                                            </span>
                                            <span className="block text-xs text-gray-600">m²</span>
                                        </div>
                                        )}
                                        {node.frontmatter.wohnungen && (
                                        <div className="overflow-hidden leading-4">
                                            <span className="text-sm font-semibold">
                                                {node.frontmatter.wohnungen}
                                            </span>
                                            <span className="block text-xs text-gray-600">Wohnungen</span>
                                        </div>
                                        )}
{/*                                         {node.frontmatter.bezug && (
                                        <div className="overflow-hidden leading-4">
                                            <span className="text-sm font-semibold">
                                                {node.frontmatter.bezug}
                                            </span>
                                            <span className="block text-xs text-gray-600">
                                                Bezug
                                            </span>
                                        </div>
                                        )} */}
                                    </div>
                                    {node.frontmatter.title === "Fliederweg 7, 7000 Chur" && (
                                    <a href="/pdf/fliederweg.pdf" className="text-secondary font-semibold" target="_blank">
                                       Anmeldeformular &#8594;
                                    </a>
                                    )}
                                </div>
                            </div>
                        )
                    })}
            </div>
            {!posts.length && (
                <p className="text-center">Derzeit haben wir keine Objekte im Bau.</p>
            )}
        </div>
    )
}

export default AktuelleProjekteList
